import {productService} from '../services/ProductService';
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";

const state = {
    products: [],
    productsFiltered: {
        products: [],
        resetFilter: true
    },
    productRequest : {
        loading: false
    },
    currentProduct: null,
    
}

const actions = {
    getProducts({commit}){
        commit('setProductRequest', {loading: true});
        productService.getProducts()
            .then((response) => {
                commit('setProductRequest', {loading: false});
                commit('filterProductsList', response.data);
            })
            .catch((error) => commit('setProductRequest', {loading: false, message: error}));
    },
    updateProduct({dispatch, commit, state}, product){
        commit('setProductRequest', {loading: true});
        productService.updateProduct(product)
            .then((response) => {
                toast.success(i18n.global.t("toast.successEdit"));
                commit('setProductRequest', {loading: false});
                const updateProducts = state.products.map(x => (x.productId == product.productId ? product : x));
                commit('updateProducts', updateProducts);
                commit('setCurrentProduct', null);

            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.errorEdit"));
                commit('setProductRequest', {loading: false, message: error})
            });
    },
    addProduct({commit, state}, product){
        commit('setProductRequest', {loading: true});
        productService.addProduct(product)
        .then((response) => {
            toast.success(i18n.global.t("toast.createSuccess"));
            commit('setProductRequest', {loading: false});
            const updateProducts = state.products;
            updateProducts.push(response.data)
            commit('updateProducts', updateProducts);
            commit('setCurrentProduct', null);
        })
        .catch((error) => {
            console.error("Error adding : "+error)
            toast.error(i18n.global.t("toast.createError"));
            commit('setProductRequest', {loading: false, message: error})
        });
    },
    deleteProduct({commit, state}, product){
        commit('setProductRequest', {loading: true});
        productService.deleteProduct(product)
        .then((response) => {
            toast.success(i18n.global.t("toast.deleteSuccess"));
            commit('setProductRequest', {loading: false})
            const updateProducts = state.products.filter(function(element) {
                return element.productId !== product.productId;
            });
            commit('updateProducts', updateProducts);
            commit('setCurrentProduct', null);
        })
        .catch((error) => {
            console.error("Error deleting : "+error)
            toast.error(i18n.global.t("toast.deleteError"));
            commit('setProductRequest', {loading: false, message: error})
        })
    },
    duplicateProduct({commit, state}, product){
        commit('setProductRequest', {loading: true});
        productService.addProduct(product)
        .then((response) => {
            toast.success(i18n.global.t("toast.duplicateSuccess"));
            commit('setProductRequest', {loading: false})
            const updateProducts = state.products;
            updateProducts.push(response.data)
            commit("filterProductsList", updateProducts);
            commit('setCurrentProduct', null);
        })
        .catch((error) => {
            console.error("Error deleting : "+error)
            toast.error(i18n.global.t("toast.duplicateError"));
            commit('setProductRequest', {loading: false, message: error})
        })
    },
    changeSpecificField({commit, dispatch, state}, {product, field}){
        if(field === 'userHidden'){
            product.userHidden = !product.userHidden;
        }
        if(field === 'webHidden'){
            product.webHidden = !product.webHidden;
        }
        //orderIndex & name is done too
        dispatch('updateProduct', product);
    }
}

const getters = {
  getProductFiltered(state) {
    if (state.productsFiltered.resetFilter) {
        return state.products;
    } else {
        return state.productsFiltered.products;
    }
  },
};

const mutations = {
    setProductRequest(state, {loading, message}){
        state.productRequest = {
            loading, message
        };
    },
    updateProducts(state, products){
        state.products = products;
    },
    filterProductsList(state, products){
        let result = products.sort(
          (a, b) =>
            a.productGroup.productGroupId - b.productGroup.productGroupId ||
            a.name.localeCompare(b.name)
        );
        state.products = result;
        state.productsFiltered.products = state.products;
    },
    setFilterProduct(state, itemFilter){
        state.productsFiltered.resetFilter = false;
        state.productsFiltered.products = itemFilter;
    },
    resetFilterProduct(state){
        state.productsFiltered.resetFilter = true;
        state.productsFiltered.products = state.products
    },
    setCurrentProduct(state, product){
        state.currentProduct = product;
        if (product === null){
            state.productRequest = {};
        }
    }
}

export const product = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}