<template>
  <div class="main">
    <div class="form-input-container">
      <DesignInput v-model:customValue="name" :hint="$t('productGroupPage.name')"/>

      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <design-select :hint="$t('productGroupPage.vatIn')" v-model:customValue="selectedTvaInGroup">
              <option v-for="item in productTvaGroup" :key="item.value" :value="item.value">{{ item.name }}</option>
            </design-select>
          </div>
        </div>
        <div class="col-6">
          <design-select :hint="$t('productGroupPage.vatOut')" :tooltip-text="$t('productGroupPage.vatTooltip')"
                         v-model:customValue="selectedTvaOutGroup">
            <option v-for="item in productTvaGroup" :key="item.value" :value="item.value">{{ item.name }}</option>
          </design-select>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <design-select :hint="$t('printerStrings.printerName')" v-model:customValue="selectedPrinterId">
              <option v-for="item in printers" :key="item.id" :value="item.id">{{ item.name }}</option>
            </design-select>
          </div>
        </div>
        <div class="col-6">
          <DesignInput
              v-model:customValue="orderIndex"
              type="number"
              :hint="$t('productPage.orderIndex')"
              :tooltip-text="$t('productGroupPage.orderToolltip')"
          />
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-6">
        <div class="mt-3 mr-1">
          <design-select :fullSize="true" :hint="$t('productGroupPage.userHidden')"
                         v-model:customValue="userHidden"
                         :tooltip-text="$t('productGroupPage.userHiddenTooltip')"
          >
            <option :value="true">{{ $t('generic.yes') }}</option>
            <option :value="false">{{ $t('generic.no') }}</option>
          </design-select>
        </div>
      </div>
      <div class="col-6">
        <div class="mt-3 mr-1">
          <design-select :fullSize="true" :hint="$t('productGroupPage.webHidden')"
                         v-model:customValue="webHidden"
                         :tooltip-text="$t('productGroupPage.userHiddenTooltip')"
          >
            <option :value="true">{{ $t('generic.yes') }}</option>
            <option :value="false">{{ $t('generic.no') }}</option>
          </design-select>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="mt-4 form-input-container">
        <design-input-color-list :hint="$t('generic.color')" :custom-value="groupColor"
                                 v-model:custom-value="groupColor"/>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6 ">
        <div class="mr-1">
          <DesignInput
              v-model:customValue="maxChoicePaid"
              type="number" min="1"
              :hint="$t('productGroupPage.maxChoicePaid')"
              :tooltip-text="$t('productGroupPage.maxChoicePaidToolTip')"
          />
        </div>
      </div>
      <div class="col-6">
        <DesignInput
            v-model:customValue="maxChoiceFree"
            type="number" min="1"
            :hint="$t('productGroupPage.maxChoiceFree')"
            :tooltip-text="$t('productGroupPage.maxChoiceFreeToolTip')"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="mt-3 mr-1">
          <design-select
              :fullSize="true"
              :hint="$t('productGroupPage.KitchenPrintEnabled')"
              v-model:customValue="kitchenPrintEnabled"
              :tooltip-text="$t('productGroupPage.kitchenPrintEnabledTooltip')"
          >
            <option :value="true">{{ $t('generic.yes') }}</option>
            <option :value="false">{{ $t('generic.no') }}</option>
          </design-select>
        </div>
      </div>
      <div class="col-6 mt-3">

        <design-select
            :hint="$t('productPage.productGroupParent')"
            v-model:customValue="selectedProductGroupId"
        >
          <option value="null">None</option>
          <option
              v-for="item in productGroups"
              :key="item.productGroupId"
              :value="item.productGroupId"
          >
            {{ item.name }}
          </option>
        </design-select>
      </div>
    </div>

    <p v-if="editGroupRequest.message || addGroupRequest.message" class="text-status error">
      {{ editGroupRequest.message || addGroupRequest.message }}</p>
    <div class="button-container mt-5 pb-5">
      <design-button class="button" @customClick="cancel()" type="secondary"> {{ $t('generic.cancel') }}</design-button>
      <design-button class="button" @customClick="submitGroup()"
                     :loading="editGroupRequest.loading || addGroupRequest.loading"> {{ $t('generic.save') }}
      </design-button>
    </div>
  </div>
</template>

<script>
import DesignInput from '../components/DesignInput.vue';
import DesignButton from '../components/DesignButton.vue';
import DesignSelect from '../components/DesignSelect.vue';
import {mapMutations, mapActions, mapState} from "vuex";
import DesignInputColorList from "@/views/components/DesignInputColorList.vue";

export default {
  name: 'EditProductGroupDialog',
  components: {
    DesignInputColorList,
    DesignInput,
    DesignButton,
    DesignSelect,
  },
  methods: {
    ...mapMutations('productGroup', ['setEditingGroup']),
    ...mapActions('productGroup', ['updateProductGroup', 'addProductGroup', 'getProductGroups']),
    ...mapActions('printer', ['getPrinters']),
    ...mapState("productGroup", ["productGroups"]),
    submitGroup: function () {
      const parentThis = this;
      if (this.editingGroup) {
        const newGroupData = this.editingGroup;
        newGroupData.name = this.name;
        newGroupData.orderIndex = this.orderIndex;
        newGroupData.maxChoicePaid = this.maxChoicePaid;
        newGroupData.maxChoiceFree = this.maxChoiceFree;

        newGroupData.vatIn = this.selectedTvaInGroup;
        newGroupData.vatOut = this.selectedTvaOutGroup;
        newGroupData.printerLocalIp = this.printerIp;
        newGroupData.userHidden = this.userHidden;
        newGroupData.webHidden = this.webHidden;
        newGroupData.kitchenPrintEnabled = this.kitchenPrintEnabled;
        newGroupData.printer = this.printers.find(function (element) {
          return element.id.toString() === parentThis.selectedPrinterId.toString()
        });
        newGroupData.parentGroupId = this.selectedProductGroupId;
        this.updateProductGroup(newGroupData);
      } else {
        const newGroupData = {};
        newGroupData.name = this.name;
        newGroupData.orderIndex = Number(this.orderIndex);
        newGroupData.maxChoicePaid = Number(this.maxChoicePaid);
        newGroupData.maxChoiceFree = Number(this.maxChoiceFree);

        newGroupData.vatIn = this.selectedTvaInGroup;
        newGroupData.vatOut = this.selectedTvaOutGroup;
        newGroupData.printerLocalIp = this.printerIp;
        newGroupData.userHidden = this.userHidden;
        newGroupData.webHidden = this.webHidden;
        newGroupData.kitchenPrintEnabled = this.kitchenPrintEnabled;
        newGroupData.printer = this.printers.find(function (element) {
          return element.id.toString() === parentThis.selectedPrinterId.toString()
        });
        newGroupData.color = this.groupColor;
        newGroupData.parentGroupId = this.selectedProductGroupId;

        this.addProductGroup(newGroupData);
        console.log(newGroupData);
      }
    },
    cancel() {
      //no need for setAddingGroup because the setter disable the adding flag
      this.setEditingGroup(null);
    },
  },
  computed: {
    ...mapState('productGroup', ['editingGroup', 'editGroupRequest', 'addGroupRequest', 'getGroupsRequest', 'productGroups']),
    ...mapState('printer', ['printers'])
  },
  data() {
    return {
      name: "",
      printerIp: "",
      color: "",
      groupColor: "",
      userHidden: false,
      webHidden: false,
      selectedPrinterId: null,
      kitchenPrintEnabled: true,
      orderIndex: 0,
      maxChoiceFree: 0,
      maxChoicePaid: 0,
      productTvaGroup: [
        {"name": "0%", "value": 0},
        {"name": "6%", "value": 6},
        {"name": "12%", "value": 12},
        {"name": "21%", "value": 21},
      ],
      selectedTvaInGroup: {},
      selectedTvaOutGroup: {},
      parentGroupId: ""
    }
  },
  mounted() {
    if (this.editingGroup) {
      this.name = this.editingGroup.name;
      this.orderIndex = this.editingGroup.orderIndex;
      this.maxChoicePaid = this.editingGroup.maxChoicePaid;
      this.maxChoiceFree = this.editingGroup.maxChoiceFree;

      this.selectedTvaInGroup = String(this.editingGroup.vatIn);
      this.selectedTvaOutGroup = String(this.editingGroup.vatOut);
      this.printerIp = this.editingGroup.printerLocalIp;
      this.userHidden = this.editingGroup.userHidden;
      this.webHidden = this.editingGroup.webHidden;
      this.kitchenPrintEnabled = this.editingGroup.kitchenPrintEnabled;
      if (this.editingGroup.printer) {
        this.selectedPrinterId = this.editingGroup.printer.id
      }
      if (this.editingGroup.color) {
        this.groupColor = this.editingGroup.color;
      }
      this.selectedProductGroupId = this.editingGroup.parentGroupId;

    }

    if ($.isEmptyObject(this.printers)) {
      this.getPrinters();
    }
    this.parentGroupId = this.editingGroup?.parentGroupId;
  }


}
</script>

<style lang="scss" scoped>
.main {
  width: auto;
  padding: 20px 20px;
}

select {
  padding: 10px 20px 10px 20px;
}

.form-input-container {
  display: flex;
  flex-direction: column;
  row-gap: $default-edge-spacing-l;
}

.button-container {
  display: flex;
  justify-content: stretch;
  align-content: stretch;
  column-gap: $default-edge-spacing-l;
  margin-top: $default-edge-spacing-xxl;

  .button {
    width: 100%;
  }
}

.colorPicker-style {
  padding-right: 10px;
}

.input-hint-color {
  padding: 0 !important;
  font-size: 13px;
  font-weight: normal;
  color: $color-secondary-text;
  opacity: 0.8;
  margin: 0 0 8px 8px;
}

.profile-data-container {
  padding: 0 0 30px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .letter-icon {
    color: $color-primary-text;
    background-color: $color-primary-blue;
    border-radius: 150px;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    padding: 6px;
  }

  .profile-text-container {
    flex-grow: 1;
    padding-left: $default-edge-spacing-l;
    overflow: hidden;

    .title {
      font-size: $font-title;
      color: $color-primary-text;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subtitle {
      font-size: $font-subtitle;
      color: $color-secondary-text;
    }
  }

  .delete-button {
    min-width: 18px;
    max-width: 18px;
    margin-right: $default-edge-spacing-xl;
    stroke: $color-pastel-red;

  }


}

.color-picker {
  height: 50px !important;
}

@media (max-width: $screen-md) {

  .button-container {
    justify-content: center;
    flex-direction: column;
    padding-top: $default-edge-spacing;

    .button {
      margin-top: $default-edge-spacing-l;

      &:nth-child(1) {
        padding-right: 0 !important;
      }
    }


  }

}

</style>